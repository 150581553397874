import React from 'react';
import './adminStyle/adminMain.css';
import { useNavigate } from 'react-router-dom';

function AdminMain() {
    const navigate = useNavigate();
    return (
        <div className='admin-mainPage'>
            <div className='admin-mainPage-top'>
                <div className='main-button' onClick={()=>navigate("/The_h1ddenAdm1n_page/checkTradingRecord")}>交易紀錄</div>
                <div className='main-button' onClick={()=>navigate("/The_h1ddenAdm1n_page/checkLeasingRecord")}>的士招租</div>
            </div>
            <div className='admin-mainPage-mid'>
                <div className='main-button' onClick={()=>navigate("/The_h1ddenAdm1n_page/checkTaxi")}>的士資料</div>
                <div className='main-button' onClick={()=>navigate("/The_h1ddenAdm1n_page/checkLicensePrice")}>的士牌價</div>
            </div>
            <div className='admin-mainPage-bottom'>
                <div className='main-button' onClick={()=>navigate("/The_h1ddenAdm1n_page/checkChannelVideo")}>頻道</div>
            </div>
        </div>
    );
}

export default AdminMain;