import axios from 'axios';
import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';

function AdminInsertPrice() {

    const navigate = useNavigate();
    const [date, setDate] = useState();
    const [urbanPrice, setUrbanPrice] = useState();
    const [ntPrice, setNtPrice] = useState();


    const handleDateChange = (e) =>{
        setDate(e.target.value);
    }

    const handleUrbanPriceChange = (e) => {
        setUrbanPrice(e.target.value);
    }

    const handleNtPriceChange = (e) => {
        setNtPrice(e.target.value);
    }

    const sumbitPriceRecord = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+"/price/insertPrice", {area:"urban", priceDate:date, licensePrice:urbanPrice}).then((response)=>{
            console.log(response);
        }).catch((error)=>{
            console.log(error);
        });
        axios.post(process.env.REACT_APP_BASE_URL+"/price/insertPrice", {area:"nt", priceDate:date, licensePrice:ntPrice}).then((response)=>{
            console.log(response);
        }).catch((error)=>{
            console.log(error);
        });
        navigate("/The_h1ddenAdm1n_page/checkLicensePrice");
    }

  return (
    <div className='adminInsertPrice'>
        <div className='adminInsertPrice-formContainer'>
            <form>
                <label>日期:
                    <input type='date' onChange={handleDateChange} value={date}/>
                </label>
                <label>市區牌價:
                    <input type='number' onChange={handleUrbanPriceChange} value={urbanPrice}/>
                </label>
                <label>新界牌價:
                    <input type='number' onChange={handleNtPriceChange} value={ntPrice}/>
                </label>
                <button onClick={sumbitPriceRecord}>確定</button>
            </form>
        </div>
    </div>
  );
}

export default AdminInsertPrice;