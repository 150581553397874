import React from 'react';
import AdminLeaseForm from '../adminComponents/AdminLeaseForm';

function AdminInsertLease() {
  return (
    <div>
        <AdminLeaseForm></AdminLeaseForm>
    </div>
  );
}

export default AdminInsertLease;