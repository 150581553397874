import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import '../style/Nav.css';
import '../style/phoneNTab/Nav_PNT.css';

import axios  from 'axios';
//import icons
import FacebookIcon from './FacebookIcon';
import WhatsappIcon from './WhatsappIcon';

//import price bar
import NavBarPrice from './NavBarPrice';

//import hamburger menu
import HamburgerMenu from './HamburgerMenu';

function NavBar() {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [prices, setPrices] = useState([]);
  const [year, setYear] = useState((new Date()).getFullYear());
  const [month, setMonth] = useState(parseInt(new Date().getMonth() + 1));
  const [day, setDay] = useState(parseInt(new Date().getDate()));

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/price/getLatestPrice`).then((response)=>{
        setPrices(response.data.latestPrice[0]);
        let aNewDate = new Date(prices[0].price_date);
        setYear(parseInt(aNewDate.getFullYear()));
        setMonth(parseInt(aNewDate.getMonth() + 1));
        setDay(parseInt(aNewDate.getDate()));
    }).catch((error)=>{
        console.log(error);
    });
})

  
  return (
    
    <div className='navBar'>
    <>
    <HamburgerMenu></HamburgerMenu>
      <div className='navTop'>
      <a href="/" className='logoOnNav'>
          <img alt="starpower logo" src={logo}></img>
        </a>  
        <div style={{flexDirection:'column'}} className='priceOnBarContainer'>
          
              {prices.map((latestPrice, index)=>{
                return (<NavBarPrice key={index} latestPrice={latestPrice} today={year+"/"+month+"/"+day}></NavBarPrice>);
              })}
              <div className='navBarPriceClaim'>*牌價只供參考,<br></br>更新日期為{year}年{month}月{day}日</div>
        </div>
      </div>
      <Navbar bg="white" variant="light" className='navbar-bottom'> 
        <Container>
          <Navbar.Collapse>
          <Nav className="me-auto">
            <Nav.Link href="/" className='redText theNavLink'><p>主頁</p></Nav.Link>
            <Nav.Link href="/channel" className='blueText theNavLink'><p>最新動態</p></Nav.Link>
            <Nav.Link href="/price" className='greenText theNavLink'><p>牌價走勢</p></Nav.Link>
            <Nav.Link href="/lease" className='redText'>的士招租</Nav.Link>
            <Nav.Link href="/record" className='redText theNavLink'><p>交易紀錄</p></Nav.Link>
            {/*<Nav.Link href="/market" className='greenText theNavLink'><p>市場動態</p></Nav.Link>*/}
            <NavDropdown 
              title="買賣需知"
              show={show}
              onMouseEnter={()=>setShow(true)}
              onMouseLeave={()=>setShow(false)}
              onClick={()=>{/*navigate("/tradingKnowledge")*/}}
              className='redText' 
            >
              <NavDropdown.Item href='/tradingKnowledge/advantage'>的士牌照好處</NavDropdown.Item>
              <NavDropdown.Item href='/tradingKnowledge/taxiKnowledge'>的士基本知識</NavDropdown.Item>
              {/*<NavDropdown.Item href='/tradingKnowledge/tradingProcess'>的士買賣程序</NavDropdown.Item>*/}
              <NavDropdown.Item href='/tradingKnowledge/mortgage'>的士按揭</NavDropdown.Item>
              <NavDropdown.Item href='/tradingKnowledge/feesAndCost'>買賣收費及營運成本</NavDropdown.Item>
              <NavDropdown.Item href='/tradingKnowledge/managementAndIncome'>的士管理及租金收入</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/faq" className='greenText'>常見問題</Nav.Link>
            {/*<Nav.Link href="/talk" className='blueText'>投資講座</Nav.Link>*/}
            
            <Nav.Link href="/aboutus" className='blueText theNavLink'><p>關於我們</p></Nav.Link>
            <Nav.Link href="/contactus" className='blueText'>聯絡我們</Nav.Link>
            <FacebookIcon></FacebookIcon>
            <WhatsappIcon></WhatsappIcon>
          </Nav>
          
         
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
    </div>
  );
}

export default NavBar;