import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function AdminLeaseForm(props) {
    const [rentalFee, setRentalFee] = useState(props.leaseRecord? props.leaseRecord.rentalFee:null);
    const [licenseNumber, setLicenseNumber] = useState(props.leaseRecord? props.leaseRecord.licenseNumber:null);
    const [shiftType, setShiftType] = useState(props.leaseRecord? props.leaseRecord.shiftType:null);
    const [years, setYears] = useState(props.leaseRecord?props.leaseRecord.years:null);
    const [recordId, setRecordId] = useState(props.leaseRecord ? props.leaseRecord.recordNumber:null);
    const [carLicenses, setCarLicense] = useState([]);

    useEffect(()=>{
        axios.get(process.env.REACT_APP_BASE_URL+'/taxiLicense/getNumber').then((response)=>{
            setCarLicense(response.data);
        }).catch((error)=>{
            console.log(error);
        });
    });

    let link = process.env.REACT_APP_BASE_URL + (props.leaseRecord? "/getLease/updateLease":"/getLease/insertLease");

    const navigate = useNavigate();

    const handleRentalFeeChange = (e) => {
        setRentalFee(e.target.value);
    }

    const handleLicenseNumberChange = (e) => {
        setLicenseNumber(e.target.value);
    }

    const handleShiftTypeChange = (e) => {
        setShiftType(e.target.value);
    }

    const handleYearsChange = (e) => {
        setYears(e.target.value);
    }

    const submitLeaseForm = (e) => {
        e.preventDefault();
        console.log({rentalFee, licenseNumber, shiftType, years, recordId})
        let sendDetail = {};
        if(props.leaseRecord){
            sendDetail = {rentalFee, licenseNumber, shiftType, years, recordId};
        }
        else{
            sendDetail = {rentalFee, licenseNumber, shiftType, years};
        }
        axios.post(link, sendDetail).then((response)=>{
            console.log(response);
            navigate("/The_h1ddenAdm1n_page/checkLeasingRecord");
        }).catch((error)=>{
            console.log(error)
        })
    }

  return (
    <div className='adminLeaseForm'>
        <div className='adminLeaseForm-container'>
            <form>
                <label>租金:
                    <input type="number" min="0" onChange={handleRentalFeeChange} value={rentalFee}/>
                </label>
                <label>
                    牌照號碼:
                    <select id="taxi-license"  onChange={handleLicenseNumberChange}>
                        {props.leaseRecord ? <option value = {props.leaseRecord.licenseNumber} selected disabled hidden>{props.leaseRecord.licenseNumber} </option> : <option value="" selected disabled hidden>-</option>}
                        {carLicenses.map((carLicense, index)=>{
                            return(<option value={carLicense.license_number} key={index}>{carLicense.license_number}</option>);
                        })}
                    </select>
                </label>
                <label>種類:
                    <input onChange={handleShiftTypeChange} value={shiftType}/>
                </label>
                <label>年份:
                    <input onChange={handleYearsChange} value={years}/>
                </label>
                <button onClick={submitLeaseForm}>提交</button>
            </form>
        </div>
    </div>
  );
}

export default AdminLeaseForm;