import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MD5, SHA256 } from 'crypto-js';
import '../adminStyle/adminTaxiLicense.css'
import { useNavigate } from 'react-router-dom';

function AdminCheckTaxi() {
    const navigate = useNavigate();
    let pw = MD5(26129896);
    pw = SHA256(pw);
    const [taxiLicenses, setTaxiLicenses] = useState([]);
    useEffect(()=>{
        axios.get(process.env.REACT_APP_BASE_URL+"/taxiLicense/getAllTaxiLicense_"+pw).then((response)=>{
            setTaxiLicenses(response.data);
        }).catch((error)=>{
            console.log(error);
        })
    },[]);
  return (
    <div className='adminCheckTaxi'>
        <div className='adminCheckTaxi-container-add' onClick={()=>navigate("/The_h1ddenAdm1n_page/insertTaxi")}>+</div>
        <div className='adminCheckTaxi-taxis-container'>
            {taxiLicenses.map((taxi, key)=>{
                return(
                    <div key={key} className='singleAdminTaxiRow'>
                        <div>車牌號碼:{taxi.license_number}</div>
                        <div>種類:{taxi.brand}</div>
                        <div>地區:{taxi.area}</div>
                        <div
                            className='adminCheckTaxi-ModifyButton'
                            onClick={()=>{
                                navigate("/The_h1ddenAdm1n_page/modifyTaxi",{state:{
                                    taxiLicense:taxi.license_number,
                                    carType: taxi.brand,
                                    area:taxi.area
                                }})
                            }}
                        >修改</div>
                    </div>
                );
            })}
        </div>
    </div>
  );
}

export default AdminCheckTaxi;