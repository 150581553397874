import React from 'react';
import '../../style/TradingKnowledge.feesAndCosts.css';

function TradingKnowledgeFeesAndCosts() {
  return (
    <div className='tradingKnowledgeFeesAndCosts'>
        <div className='tradingKnowledgeFeesAndCosts-titleContainer'>
            <h1>買賣收費及營運成本</h1>
            <hr className='title-hr'></hr>
        </div>
        <div className='tradingKnowledgeFeesAndCosts-contentContainer'>
            <p>買賣的士牌照所需要的收費成本主要分成兩種，買賣交易收費成本以及每年營運成本。<br></br>作為準買家的你，必須了解當中細節。</p>
            <br></br>
            <p>買賣交易收費成本:</p>
            <ul>
              <li>牌照過戶費 (HK$1,000) (由買家負責)</li>
            </ul>
            <p>每年營運成本:</p>
            <ul>
              <li>驗車費 (HK$585)</li>
              <li>車輛牌照費 (HK$114)</li>
              <li>商業登記證 (HK$2,150)</li>
              <li>第三者保險或全保 (約HK$25,000至HK$45,000)</li>
              <li>保養及維修 (約HK$2,000至HK$16,000) (視乎車輛新舊及損耗)</li>
            </ul>
        </div>
    </div>
  );
}

export default TradingKnowledgeFeesAndCosts;