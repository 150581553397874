import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function PriceChart(props) {
  return (
        <ResponsiveContainer width="70%">
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"/>
          <YAxis unit='萬' domain={[320, 450]}/>
          <Tooltip />
          <Legend />
          <Line name='市區' type="monotone" dataKey="urban" stroke="#FF1111" hide={!props.showArea.urban}/>
          <Line name='新界' type="monotone" dataKey="nt" stroke="#82ca9d" hide={!props.showArea.nt}/>
        </LineChart>

        
      </ResponsiveContainer>
  );
}

export default PriceChart;