import React, { useState } from 'react';
import axios from 'axios';

function PriceInsert() {
    const [value, setValue] = useState("");
    const submitPrice = (e) => {
        e.preventDefault();

        axios.post(`${process.env.REACT_APP_BASE_URL}/price/insertPrice`, {"licensePrice": value}).then((response) => {
            setValue("");
        });
        
    }
    const handleChange = (e) => {
        setValue(e.target.value);
    }
  return (
    <div>
        <form>
            <label>
                price:
                <input type='number' name="licensePrice" value={value}  onChange={handleChange}/>
            </label>
            <input type='submit' value='submit' onClick={submitPrice} />
        </form>
    </div>
  );
}

export default PriceInsert;