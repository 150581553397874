import React from 'react';
import '../../style/TradingKnowledge.managementAndIncome.css';

function TradingKnowledgeManagementAndIncome() {
  return (
    <div className='tradingKnowledgeMangementAndIncome'>
    <div className='tradingKnowledgeMangementAndIncome-titleContainer'>
        <h1>鴻昇車行管理及租金收入</h1>
        <hr className='title-hr'></hr>
    </div>
    <div className='tradingKnowledgeMangementAndIncome-contentContainer'>
        <p>
        鴻昇車行提供專業的的士管理服務,可以幫助您找到司機並處理車輛的日常管理,讓您可以輕鬆收取穩定的租金收入,而不必擔心車輛的日常事務。

 
        </p>
        <p>
          溫馨提示:<br></br>
          在選擇的士管理服務時，車主必須注意以下條款:
        </p>
        <ul>
        <li>租賃協議是否有年限規定？提前結束租約是否須支付遣散費？提前多久需要告知對方取消租約？</li>
          <li>租賃協議是否清楚註明基本的維護費用？有哪些成本不在此範疇內？</li> 
          <li>若遭遇事故，最大賠償金額為何？</li>
          <li>每月的租金是否為固定數額？是否有可能突然下降？在何種情形會有租金扣減的情形？</li>
        </ul>
        <br></br>
        <p>根據現時香港鴻昇車行提供的的士管理服務，車主可收取的合理租金(包基本維修保養)如下:</p>
        <ul>
          <li>市區的士:
            <ul>
              <li>全新混能車 (約HK$21,400)</li>
              <li>2009年-2019年4座位石油氣車 (約HK$14,500 - HK$17,500)</li>
              <li>2000年-2009年5座位石油氣車 (約HK$13,000)</li>
            </ul>
          </li>
          <li>新界的士:
            <ul>
              <li>全新混能車 (約HK$16,600)</li>
              <li>2009年-2019年4座位石油氣車 (約HK$10,900 - HK$13,300)</li>
              <li>2000年-2009年5座位石油氣車 (約HK$9,700)</li>
            </ul>
          </li>
        </ul>
    </div>
</div>
  );
}

export default TradingKnowledgeManagementAndIncome;