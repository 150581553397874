import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import '../adminStyle/adminNav.css';

function AdminNavbar() {
  return (
    <Navbar bg="dark" data-bs-theme="dark" className='adminNavBar'>
        <Container>
            <Nav className='me-auto'>
                <Nav.Link href='/The_h1ddenAdm1n_page/checkTradingRecord' className='adminNavTag'>交易紀錄</Nav.Link>
                <Nav.Link href='/The_h1ddenAdm1n_page/checkLeasingRecord' className='adminNavTag'>的士招租</Nav.Link>
                <Nav.Link href='/The_h1ddenAdm1n_page/checkTaxi' className='adminNavTag'>的士資料</Nav.Link>
                <Nav.Link href='/The_h1ddenAdm1n_page/checkLicensePrice' className='adminNavTag'>的士牌價</Nav.Link>
                <Nav.Link href='/The_h1ddenAdm1n_page/checkChannelVideo' className='adminNavTag'>的士市場</Nav.Link>
            </Nav>
        </Container>
    </Navbar>
  );
}

export default AdminNavbar;