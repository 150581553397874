import React from 'react';

import AdminRecordForm from '../adminComponents/AdminRecordForm';

function AdminupInsertRecord() {
  return (
    <AdminRecordForm></AdminRecordForm>
  );
}

export default AdminupInsertRecord;