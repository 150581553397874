import React from 'react';
import '../style/Faq.css'
import QuestionAnswer from '../components/QuestionAnswer';

function Faq() {
  const questionList = [
    {
      question: "如何獲取的士牌照?",
      answer: "若您有意向在香港購買的士牌照,鴻昇車行是你的選擇。我們提供全方位的專業服務,包括的士牌照買賣代理以及交易、結算、交收等服務,確保您能夠在符合法規的情況下順利完成交易。"
    },
    {
      question: "我如何知道的士牌照的當前價值？",
      answer: "您可以隨時訪問鴻昇車行的官方網站查詢相關信息。我們每天都會更新市場上的最新成交記錄,為公眾提供最新、最可靠的的士牌照價格趨勢。"
    },
    {
      question: "我是否可以只購買的士牌照,而不購買車輛?",
      answer: `在香港,的士牌照的購買是與二手的士車輛捆綁在一起的。換句話說,您必須同時購買的士車輛和牌照。`
    },
    {
      question: "購買的士牌照需要多少首期?金融機構會提供按揭服務嗎?",
      answer: "首期金額取決於您的具體需求。一般來說,銀行或其他財務機構可能會提供部分的按揭貸款,最多可以達到成交價的85%(包括牌價和車價)。"
    },
    {
      question: "獲得的士的按揭貸款容易嗎?",
      answer: "實際上,獲得的士的按揭貸款相對於物業的按揭貸款來說要簡單一些。這是因為的士牌照本身就具有穩定的租金收入,而且許多銀行和財務機構都提供專門的的士按揭計劃。"
    },
    {
      question: "如果我已經購買了的士牌照和車輛,但是我並不打算自己開車,也不知道如何找到司機和管理,我該怎麼辦?",
      answer: "鴻昇車行提供專業的的士管理服務,可以幫助您找到司機並處理車輛的日常管理,讓您可以輕鬆收取穩定的租金收入,而不必擔心車輛的日常事務。"
    }
  ]

  return (
    <div className='faq'>
      <div className='faq-titleContainer'>
        <h1>常見問題</h1>
        <hr className='title-hr'></hr>
      </div>
      
      <QuestionAnswer questionList={questionList} title={"問題"}></QuestionAnswer>
      <div>
        以上回答希望能幫助您了解更多關於的士牌照的相關資訊。如有其他問題，歡迎隨時向我們查詢。
      </div>
    </div>
  );
}

export default Faq;