import React from 'react';
import facebookIcon from '../images/facebookIcon.png';

function FacebookIcon() {
  return (
    <a href="https://www.facebook.com/TaxiPowerManagementLtd" target='blank' className='nav-icon'>
        <img src={facebookIcon} style={{
            width: "50px",
            height: "50px"
        }}></img>
    </a>
  );
}

export default FacebookIcon;