import React, { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import ContactUsForm from '../components/ContactUsForm';
import GoogleMapCom from '../components/GoogleMapCom';
import '../style/ContactUs.css'
import '../style/phoneNTab/ContactUs_PNT.css'

function ContactUs() {
  
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+"/contactUs/getInfo").then((response)=>{
      setCompanyInfo(response.data);
    }).catch((error)=>
    {
      console.log(error)
    });
  },[]);

  return (
    <div className='contactUs'>
        <div className='contactUs-titleContainer'>
          <h1>聯絡我們</h1>
          <hr className='title-hr'></hr>
        </div>
        <div className='contactUs-contentContainer'>
          <div className='contactUs-info'>
            <div className='contactUs-info-chName'>{companyInfo.company_chinese_name}</div>
            <div className='contactUs-info-enName'>{companyInfo.company_english_name}</div>
            <div className='contactUs-info-facebook'>Facebook: <a href={companyInfo.company_facebook} target='blank'>鴻昇車行投資有限公司 的士 買賣 出租 廣告 投資 托管 車隊 服務</a></div>
            <div className='contactUs-info-tel'>聯絡電話: {companyInfo.company_phone}</div>
            <div className='contactUs-info-whatsapp'>Whatsapp: <a href={companyInfo.company_whatsapp} target='blank'>6802 6808</a></div>
            <div className='contactUs-info-fax'>傳真: {companyInfo.copany_fax}</div>
            <div className='contactUs-info-address'>地址: {companyInfo.compay_address}</div>
          </div>
          <GoogleMapCom></GoogleMapCom>
        </div>
        <ContactUsForm></ContactUsForm>
    </div>
  );
}

export default ContactUs;