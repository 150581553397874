import React, { useState } from 'react';
import background from "../images/aboutUsBackgroud.jpeg";
import marketInfoImage from "../images/marketInfo-image.jpg";
import doorSafety from "../images/doorSafety.jpg";
import management from "../images/management.jpg";
import '../style/AboutUs.css';
import CardGroup from 'react-bootstrap/CardGroup';
import AboutUsKnowledgeCard from '../components/AboutUsKnowledgeCard';
import ReactPlayer from 'react-player';
import '../style/phoneNTab/AboutUs_PNT.css'

function AboutUS() {
  const serviceInfo= [
    {
      title: '最新市場資訊',
      imgDirectory: marketInfoImage,
      content:'我們提供最新的市場資訊，不論市區/新界的士牌價、成交記錄、市場動態及我們提供的的士資訊，讓你一手掌握最新的士行情，更輕鬆部署每一步。',
      marginLeft:'25%',
      marginRight:'auto'
    },
    {
      title: '安全買賣的士牌照',
      imgDirectory: doorSafety,
      content:'為我們以安全性為首要考量。鴻昇車行致力於提供安全可靠的交易環境。我們採取嚴格的安全措施，保護您的個人和交易信息。我們的平台符合最高的安全標準，並遵守相關的法規和規定。您可以放心地進行士牌買賣，知道您的交易和個人資料受到嚴密的保護。我們的目標是確保每一筆交易都安全無虞，為您提供安心和可靠的交易體驗。',
      marginLeft:'auto',
      marginRight:'auto'
    },
    {
      title: '的士牌綜合管理服務',
      imgDirectory: management,
      content:'我們提供專業的士牌管理服務，由我們代替車主跟進雜項。我們確保車主獲得穩定的固定收入，無需擔心收入波動。藉由我們的專業服務，車主能夠輕鬆獲取隱定收入並專注於其他事務。',
      marginLeft:'auto',
      marginRight:'25%'
    }
  ];

  const [selectedServiceInfo, setServiceInfo] = useState({display:'none'});

  const giveInfoLink = (title) => {
    switch(title){
      case '最新市場資訊':
        return (
        <div className='aboutUs-selectedService-market aboutUs-selectedService-choice'>
          <a href="/price"><div>的士牌價走勢</div></a>
          <a href="record"><div>最近成交紀錄</div></a>
          <a href="/"><div>最新市場動態</div></a>
        </div>
        );
        break;
      case '安全買賣的士牌照':
        return (
        <div className='aboutUs-selectedService-agent aboutUs-selectedService-choice'>
          <a href="/tradingKnowledge/advantage"><div>的士牌價優勢</div></a>
          <a href="tradingKnowledge/taxiKnowledge"><div>的士基本知識</div></a>
          <a href="/tradingKnowledge/tradingProcess"><div>的士買賣程序</div></a>
          <a href="/tradingKnowledge/mortgage"><div>的士按揭</div></a>
          <a href="/tradingKnowledge/feesAndCost"><div>買賣收費及營運成本</div></a>
        </div>
        );
        break;
      case '的士牌綜合管理服務':
        return (
          <div className='aboutUs-selectedService-manage aboutUs-selectedService-choice'>
            <a href="/tradingKnowledge/managementAndIncome"><div>的士官理及租金收入</div></a>
          </div>
        );
        break;
      default:
        return "";
    }
  }

  return (
    <div className="aboutUs">
      <div className='aboutUs-titleContainer'>
        <h1>關於我們</h1>
        <hr className='title-hr'></hr>
      </div>
      
      <div className="aboutUs-contentContainer" >
        <div className="aboutUs-content-transbox" style={{
           backgroundImage: `url(${background})`,
           backgroundSize:"60vw"
        }}>
          <p className='aboutUs-contentContainer-text'>「 鴻昇車行投資有限公司 <br></br> STAR POWER MOTORS INVESTMENT LIMITED 」<br></br>荃灣鴻昇車行一如既往都以公正開放、匡扶新人為經營原則，而家更推出 <br></br>「你入行、我搞掂一條龍全包服務！」 只要你有香港駕駛執照， <br></br> 嚟到鴻昇報考所有費用全免，職前訓練、在職技巧、的士司機證，無需周圍騰， <br></br> 鴻昇幫你搞掂晒，咁就輕輕鬆鬆入行順風喇！</p>
        </div>
        {/*<div className='aboutUs-content-marketInfo'>
          <div className='aboutUs-content-marketInfo-content'>
            <h3>市場資訊中心</h3>
            <p>香港的士交易所透過旗下的數據服務中心提供可靠的市場資訊，包括最新各區的士牌價、成交記錄、市場相關新聞和市場動態，讓公眾掌握最新的士牌照買賣的可靠資訊。</p>
            <a href="/price"><div>的士牌價走勢</div></a>
            <a href="record"><div>最近成交紀錄</div></a>
            <a href="/"><div>最新市場動態</div></a>
          </div>
          <div className='aboutUs-content-marketInfo-image'>
            <img src={marketInfoImage} alt="starpower"/>
          </div>
        </div>

        
        <div className='aboutUs-content-licenseAgent'>
          <div className='aboutUs-content-licenseAgent-image'>
            <img src={licenseAgent} alt="starpower"/>
          </div>
          <div className='aboutUs-content-licenseAgent-content'>
            <h3>的士牌照買賣代理</h3>
            <p>為確保市場公平有序地運作，香港的士交易所提供已登記之專業的士牌照買賣代理，為買賣雙方提供交易、結算、交收及的士管理服務，以誠實、專業的態度處理買賣細節，確保交易順利完成。</p>
            <a href="/tradingKnowledge/advantage"><div>的士牌價優勢</div></a>
            <a href="tradingKnowledge/taxiKnowledge"><div>的士基本知識</div></a>
            <a href="/tradingKnowledge/tradingProcess"><div>的士買賣程序</div></a>
            <br></br>
            <a href="/tradingKnowledge/mortgage"><div>的士按揭</div></a>
            <a href="/tradingKnowledge/feesAndCost"><div>買賣收費及營運成本</div></a>
          </div>
        </div>
         
        <div className='aboutUs-content-management'>
          
        <div className='aboutUs-content-management-content'>
            <h3>的士管理服務</h3>
            <p>香港的士交易所致力提供優質的的士管理服務，從而車主無需擔心尋找司機的煩惱，我們會有專人跟進一切管理的士的瑣碎事情，車主便可安心地直接收取穩定的租金回報。</p>
            <a href="/tradingKnowledge/managementAndIncome"><div>的士官理及租金收入</div></a>
          </div>
          <div className='aboutUs-content-management-image'>
            <img src={management} alt="starpower"/>
          </div>
          
        </div> */}
      <div className='aboutUs-videoContainer'>
        <ReactPlayer
          url="https://www.facebook.com/watch/?v=4089172181109811"
          controls
        >
        </ReactPlayer>
      </div>
      <h3>提供服務</h3>
      <CardGroup>
        {serviceInfo.map((service, key)=>{
          
          return(
          <div onClick={() => setServiceInfo({title: service.title, content: service.content, display:'block', marginLeft: service.marginLeft, marginRight: service.marginRight})} className='card card-container' key={key}>
            <AboutUsKnowledgeCard title={service.title} 
              imgDirectory={service.imgDirectory} >
            </AboutUsKnowledgeCard>
          </div>);
        })}
      </CardGroup>
      <div className='aboutUs-selectedService-arrow-up' style={{marginRight:selectedServiceInfo.marginRight, marginLeft:selectedServiceInfo.marginLeft, display:selectedServiceInfo.display}}></div>
        <div className='aboutUs-selectedServiceInfo' style={{display:selectedServiceInfo.display}}>
          <h3 className='aboutUs-selectedServiceInfo-title'>{selectedServiceInfo.title}</h3>
          <div className='aboutUs-selectedServiceInfo-content'>{selectedServiceInfo.content}</div>
          {giveInfoLink(selectedServiceInfo.title)}
        </div>
      </div>
    </div>
  );
}

export default AboutUS;