import React from 'react';
import QuestionAnswer from '../../components/QuestionAnswer';
function TradingKnowledgeAdvantage() {
    const questionList = [
        {
          question: "香港士牌照投資機會與穩定回報",
          answer: "香港目前共有18,163個士牌照，其中包括15,250個市區士牌照、2,838個新界士牌照和75個大嶼山士牌照。士牌照屬於永久性資產，並不會有實質損耗。由於交通管理的需要，當局限制了的士牌照的供應量，為投資帶來機會。自1994年以來，香港政府已經超過25年沒有再增發市區和新界的士牌照，這導致市場長期存在供不應求的情況。目前的士牌照價格約數百萬元，銀行也提供的士牌照的按揭服務，借款年限可長達28年，利率水平與房屋按揭相當。的士牌照投資具備短期、中期和長期特點。買賣成本低，融資終止費用低，政策風險低，買賣手續簡單，交收時間短，回報理想，使得的士牌照成為一項具有彈性的投資項目。有些投資者可以在一個月內完成從買入到賣出的交易，並取得可觀的回報。此外，一些客戶將購買的士牌照作為退休策劃的一部分，透過我們公司提供的專業托管服務，他們可以依靠的士牌照的租金收入支付每月的融資款項。隨著一段時間的過去，客戶將擁有一項穩定的高市值租金收入工具，並享受理想的退休生活。"
        },
        {
          question: "提供無憂的的士牌照投資回報與便捷管理",
          answer: "作為的士牌照的車主，您可以輕鬆享受穩定的投資回報，而無需自己擔心管理或尋找租客。鴻昇車行以其專業的服務聞名，致力於為您提供全面的的士包租管理服務。我們深知的士牌照投資的需求，將竭盡所能為您提供最優質的服務和支持。讓鴻昇車行成為您值得信賴的合作夥伴，共同實現成功的投資之旅。"
        },
        {
          question: "抗跌能力強的穩定投資資產",
          answer: `的士在每個城市扮演著必要的點對點接駁交通工具的角色。政府根據市民需求、道路負荷、環保評估以及的士業界和交通諮詢會的建議，調整的士牌照數量。此外，的士在旅遊業中也扮演著重要的角色，成為遊客主要的交通選擇。因此，的士具有獨特的市場價值。此外，的士的交通費用長期以來隨著通脹上升，帶動其資產價值。以上多個因素使得的士牌價具有較強的抗跌能力。的士在香港運輸業中扮演著重要角色，無論是本地乘客追趕時間或是訪港旅客需要便捷的點對點交通，的士都是他們的首選。因此，的士有其獨特的市場價值和存在價值。加上其交通費用上升和永久性質的特點，的士牌照被視為一種重要的資產，具有較強的抗跌能力。`
        }
      ]

  return (
    <div className='tradingKnowledgeAdvantage'>
        <div className='tradingKnowledgeAdvantage-titleContainer'>
          <h1>的士牌照好處</h1>
          <hr className='title-hr'></hr>
        </div>
        
        <QuestionAnswer questionList={questionList} title={"優勢"}></QuestionAnswer>
    </div>
  );
}

export default TradingKnowledgeAdvantage;