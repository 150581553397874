import React from 'react';
import AdminRecordForm from '../adminComponents/AdminRecordForm';
import { useLocation } from 'react-router-dom';

function AdminModifyRecord() {
    const location = useLocation();
    return (
        <AdminRecordForm
            tradingRecord={location.state}
        ></AdminRecordForm>
    );
}

export default AdminModifyRecord;