import React from 'react';
import AdminLeaseForm from '../adminComponents/AdminLeaseForm';
import { useLocation } from 'react-router-dom';

function AdminModifyLease() {
  const location = useLocation();
  return (
    <AdminLeaseForm
      leaseRecord = {location.state}
    ></AdminLeaseForm>    
  );
}

export default AdminModifyLease;