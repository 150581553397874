import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import LeaseRow from './LeaseRow';
import axios from 'axios';

function LeaseList() {
    const [leaseList, setLeastList] = useState([]);
    let url = `${process.env.REACT_APP_BASE_URL}/getLease`
    console.log(url);
    useEffect(()=>{
      axios.get(url).then((response)=>{
        setLeastList(response.data.leaseList[0]);
      }).catch((error) => {
        console.log(error);
      })      
    },[]);

  return (
    <ListGroup>
      {
        leaseList.map((leaseRow) => {

          let aLeaseRow ={
            area: leaseRow.area,
            car_type: leaseRow.brand,
            years: leaseRow.years,
            shift_type: leaseRow.shift_type,
            rental_fee: leaseRow.rental_fee,
          };
          return(<LeaseRow leaseRow={aLeaseRow}></LeaseRow>);
        })
      }
        
    </ListGroup>
  );
}

export default LeaseList;