import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import RecordRow from './RecordRow';
import axios from 'axios';

function RecordList() {

  const [recordList, setRecordList] = useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/getRecord`).then((response)=>{
      setRecordList(response.data.record[0]);
    }).catch((error) => {
      console.log(error);
    })
  }, []);

  return (
    <ListGroup>
      {
        recordList.map(record => {
        
        let dateTranslator = (str) => {
          str = str.split('T')[0];
          str = str.split('-')
          str = str[0] + "年" + str[1] + "月" + str[2] + "日";
          return str;
        }

        let aRecord  = {
          contractDay: dateTranslator(record.contract_date),
          licenceType: record.area,
          carType: record.brand,
          deposite: record.deposite,
          price: record.trading_price,
          tradingDay: dateTranslator(record.trading_date),
        };
        return (<RecordRow tradingRecord={aRecord}></RecordRow>)
      })}
        
    </ListGroup>
  );
}

export default RecordList;