import React, { useEffect, useState } from 'react';
import axios from 'axios';

function AdminUploadVideo() {
    const [title, setTitle] = useState();
    const [content, setContent] = useState();
    const [videoLink, setVideoLink] = useState();
    const [tag, setTag] = useState();
    const [tagOptions, setTagOptions] = useState([]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value)
    }

    const handleContentChange = (e) => {
        setContent(e.target.value);
    }

    const handleVideoLinkChange = (e) => {
        setVideoLink(e.target.value);
    }

    const handleTagChange = (e) => {
        console.log(e.target.value);
        setTag(e.target.value);
    }

    const submitChannelVideo = (e) => {
        e.preventDefault();
        if(title && content && tag){
            if (!videoLink) {
              videoLink = '';
            }
            axios.post(process.env.REACT_APP_BASE_URL +"/channel/uploadVideo", {
                title: title,
                content: content,
                videoLink: videoLink,
                tag: tag
            }).then((response)=>{
                    console.log(response);
                }
            ).catch((error)=>{
                    console.log(error);
                }
            );
        }
        else{
            alert('請輸入全部資料');
        }
    }

    useEffect(()=>{
        axios.get(process.env.REACT_APP_BASE_URL +"/channel/getTags").then((response)=>{
            setTagOptions(response.data[0]);
        }).catch((error)=>{
            console.log(error);
        });
    });

  return (
    <div className='adminUploadVideo'>
        <div className='adminUploadVideo-container'>
            <form>
                <label>標題:
                    <input value={title} onChange={handleTitleChange} required/>
                </label>
                <br/>
                <label>內容:
                    <textarea value={content} onChange={handleContentChange} required></textarea>
                </label>
                <br/>
                <label>影片連結(youtube 或 facebook貼文):
                    <input value={videoLink} onChange={handleVideoLinkChange} required/>
                </label>
                <br/>
                <label> 創建新主題:
                    <input value={tag} onChange={handleTagChange} required/>
                </label>
                或使用已有主題:
                <select onChange={handleTagChange}>
                    <option value="" selected disabled hidden>-</option>
                        {tagOptions.map((tagOption, key)=>{
                            return(<option value={tagOption.tag} key={key}>{tagOption.tag}</option>);
                        })}
                </select>
                <br/>
                <button onClick={submitChannelVideo}>新增</button>
            </form>
        </div>
    </div>
  );
}

export default AdminUploadVideo;
