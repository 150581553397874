import React, { useEffect, useState } from 'react';
import PriceChart from '../components/PriceChart';
import '../style/Price.css'
import '../style/phoneNTab/Price_PNT.css'
import {Calendar} from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import * as locales from 'react-date-range/dist/locale';
import axios from 'axios';


function Price() {
  let today = new Date();
  let thirtyDay = new Date();
  thirtyDay = new Date(thirtyDay.setDate(thirtyDay.getDate() - 30));
  //today = today.getFullYear() + '-' + ((today.getMonth() + 1) < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) ;
  //thirtyDay = thirtyDay.getFullYear() + '-' + ((thirtyDay.getMonth() + 1) < 10 ? "0"+ (thirtyDay.getMonth()+1) : + (thirtyDay.getMonth()+1)) + '-' + (thirtyDay.getDate() < 10 ? "0" + thirtyDay.getDate() : thirtyDay.getDate());
  const [isMobilePage, setIsMobilePage] = useState(false);
  const [countBy, setCountBy] = useState('countByDay');
  const [dateFrom, setDateFrom] = useState(thirtyDay);
  const [dateTo, setDateTo] = useState(today);
  const [showUrban, setShowUrban] = useState(true);
  const [showNt, setShowNt] = useState(true);
  const [chartData, setChartData] = useState([]);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [displaySelectorFrom, setDisplaySelectorFrom] = useState("block");
  const [displaySelectorTo, setDisplaySelectorTo] = useState("block");
  const [priceContentContainerHeight, setPriceContentContainerHeight] = useState("50 vh")

  const clickSelectorFrom = () => {
    if(displaySelectorFrom == "none"){
      setDisplaySelectorFrom("block");
      setPriceContentContainerHeight("50vh");
    }
    else{
      setDisplaySelectorFrom("none")
      setPriceContentContainerHeight("20vh");
    }
    setDisplaySelectorTo("none");
  }

  const clickSelectorTo = () => {
    setDisplaySelectorFrom("none");
    if(displaySelectorTo == "none"){
      setDisplaySelectorTo("block");
      setPriceContentContainerHeight("50vh");
    }
    else{
      setDisplaySelectorTo("none");
      setPriceContentContainerHeight("20vh");
    }
      
  }

  useEffect(()=>{
    //for phone page  
    setWindowSize(window.innerWidth);
    if(windowSize <= 1000 && isMobilePage == false){
      setDisplaySelectorFrom("none");
      setDisplaySelectorTo("none");
      setPriceContentContainerHeight("20vh")
      setIsMobilePage(true);
    }
      
      axios.post(`${process.env.REACT_APP_BASE_URL}/price/getPrice`, {
      countBy:countBy,
      dateFrom:dateFrom,
      dateTo:dateTo
    }).then((response)=>{
      setChartData(response);
    }).catch((error)=>{
      console.log(error);
    })
  });

  const handleCounBy = (e) => {
    setCountBy(e.target.value);
  }
  const handleDateFrom = (e) => {
    setDateFrom(e.target.value);
  }
  const handleDateTo = (e) => {
    setDateTo(e.target.value);
  }
  const handleShowUrban = (e) => {
    setShowUrban(!showUrban);
  }
  const handleShowNt = (e) => {
    setShowNt(!showNt);
  }

  const [dateState, setDateState] = useState(new Date());

  const countSelection = [
    {text:'以日計算', value:'countByDay'},
    {text:'以週計算', value:'countByWeek'},
    {text:'以月計算', value:'countByMonth'},
    {text:'以季計算', value:'countBySeason'},
    {text:'以半年計算', value:'countByHalfYear'},
    {text:'以年計算', value:'countByYear'}
  ]

  return (
    <div className='price'>
      <div className='price-titleContainer'>
        <h1>的士牌價走勢</h1>
        <hr className='title-hr'></hr>
      </div>
      <div className='price-contentContainer' style={{height: priceContentContainerHeight}}>
        <div className='price-selector'>
          <select onChange={handleCounBy}>
            {countSelection.map(option=>{
              return (<option key={option.value} value={option.value}>{option.text}</option>);
            })}
          </select>
          <div className='date-selector'>
            <div className='price-date-selector-from-PNT' onClick={clickSelectorFrom}>
              <div>日期由</div>
              <div>&#x25BC;</div>
            </div>
            <p>日期由：</p>
            <Calendar
              date={dateFrom}
              locale ={locales['zhTW']}
              onChange={item => setDateFrom(item)}
              maxDate={new Date()}
              style={{display:displaySelectorFrom}}
            />
            <div className='price-date-selector-to-PNT' onClick={clickSelectorTo}>
               <div>日期至</div>
              <div>&#x25BC;</div>
            </div>
            <p>至到：</p>
            <Calendar
              date={dateTo}
              locale ={locales['zhTW']}
              onChange={item => setDateTo(item)}
              maxDate={new Date()}
              style={{display:displaySelectorTo}}
            />
          </div>
            {/*<label onChange={handleDateFrom} >
              &nbsp; 顯示日期由:&nbsp;<input type='date' value={dateFrom}/>
            </label>
            <label onChange={handleDateTo} >
            &nbsp;至到:&nbsp;<input type='date' value={dateTo}/>
            </label>
            &nbsp;*/}
            <label className='priceDisplay-label'>
              顯示市區的士<input type='checkbox' onChange={handleShowUrban} checked={showUrban}/>&nbsp;
              顯示新界的士<input type='checkbox' onChange={handleShowNt} checked={showNt}/>
            </label>
        </div>
      </div>
      <PriceChart data={chartData.data} showArea={{'urban':showUrban, 'nt':showNt}}></PriceChart>
    </div>
  );
}

export default Price;