import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import '../style/ChannelSingleNews.css'


function ChannelSingleNews() {
    const location = useLocation();
    const [title, setTitle] = useState(location.state.title?location.state.title:'');
    const [video, setVideo] = useState(location.state?location.state.video:'');
    const [content, setContent] = useState(location.state?location.state.content:'');



    function getVideoContent(link) {
        if (link.trim()=='') {
            return '';
        }
        if (link.includes('jpg') || link.includes('png')) {
            return (
            <div className='channelNews-videoContainer'>
            <img src={link} />
            </div>);
        } else {
            return (
            <div className='channelNews-videoContainer'>
                <ReactPlayer url={link}
                className="player"
                />
            </div>);
        }
    }

  return (
        
    <div className='channelNews'>
        <div className='channelNews-titleContainer'>
            <h1>{title}</h1>
        </div>
        {getVideoContent(video)}
        <div className='channelNews-contentContainer' dangerouslySetInnerHTML={{__html:content}} />
    </div>
  );
}

export default ChannelSingleNews;
