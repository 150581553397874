import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import '../adminStyle/adminCheck.css';
import { useNavigate } from 'react-router-dom';

function AdminCheckVideo() {
    const [videos, setVideos] = useState([]);
    const [tag, setTag] = useState("*");
    const [tagSelection, setTagSelection] = useState([]);

    const navigate = useNavigate();

    useEffect(()=>{
        axios.get(process.env.REACT_APP_BASE_URL + "/channel/getTags").then((response)=>{
            setTagSelection(response.data[0]);
        }).catch((error)=>{
            console.log(error);
        })

        axios.post(process.env.REACT_APP_BASE_URL + "/channel/getVideos", {tag:tag}).then((response)=>{
            setVideos(response.data[0]);
        }).catch((error)=>{
        });
    }, [tag])

    const handleTagOnChange = (e)=>{
        setTag(e.target.value);
    }

    const clickUploadVideo= (e) => {
        navigate("/The_h1ddenAdm1n_page/uploadChannelVideo");
    }

    const clickModifyVideo = (id, title, tag, content, url) => {
        navigate("/The_h1ddenAdm1n_page/modifyChannel", {state:{
            id:id,
            title:title,
            tag:tag,
            url:url,
            content:content
        }})
    }

     const deleteVideo = (id) => {
        axios.post(process.env.REACT_APP_BASE_URL + "/channel/deleteVideo", {id:id}).then((response)=>{
            console.log(response)
            navigate(0);
        }).catch((error)=>{
            console.log(error);
        });
     }

  return (
    <div className='adminCheckVideo'>
        <label>顯示分類:
            <select onChange={handleTagOnChange}>
                <option value={"*"}>全部</option>
                {tagSelection.map((option, key)=>{
                    return(
                        <option value={option.tag} key={key}>{option.tag}</option>
                    );
                })}
            </select>
        </label>
        <div onClick={clickUploadVideo} className='uploadVideo'>+</div>
        <div className='adminCheckVideo-container'>
            {videos.map((video, key)=>{
                return(
                    <div>
                    <div className='adminCheckVideo-row' key={key} onClick={()=>{
                        clickModifyVideo(video.id, video.title, video.tag, video.content, video.videoLink)
                    }}>
                        <div className='adminCheckVideo-row-left'>
                            <ReactPlayer url={video.videoLink}/>      
                        </div>
                        <div className='adminCheckVideo-row-right'>
                            <div className='adminCheckVideo-row-right-title'>{video.title}</div>
                            <div className='adminCheckVideo-row-right-content'>{video.content.slice(0, 100)}...</div>
                        </div>
                    </div>
                    <button onClick={()=>{deleteVideo(video.id)}}>刪除上面文章</button>
                    </div>
                );
            })}
        </div>
    </div>
  )
}

export default AdminCheckVideo
