import React from 'react';
import LeaseList from '../components/LeaseList';

function Lease() {
  return (
    <div className='lease'>
      <div className='lease-titleContainer'>
        <h1>的士招租</h1>
        <hr className='title-hr'></hr>
      </div>
      <div className='lease-contentContainer'>
        <LeaseList></LeaseList>
      </div>
    </div>
  );
}

export default Lease;