import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminTaxiLicenseForm from '../adminComponents/AdminTaxiLicenseForm';

function AdminModifyTaxi() {
    const location = useLocation();
  return (
    <AdminTaxiLicenseForm
        taxiLicense={location.state}
    ></AdminTaxiLicenseForm>
  );
}

export default AdminModifyTaxi;