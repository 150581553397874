import React, { useState } from "react";
import {SHA224} from 'crypto-js';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';

function Login(){
    const navigate = useNavigate();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [cookies, setCookie] = useCookies(['auth']);

    const submitLogin = async (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL + "/login", {username:username, password: password}).then((response)=>{
            if(response.data.success){
                //save cookie
                let expires = new Date();
                expires.setTime(expires.getTime() + 1000 * 60 * 60);
                setCookie('auth', response.data.auth, {path: '/', expires})
                navigate("/The_h1ddenAdm1n_page");
            }
            else{
              //navigate(0);
            }
        }).catch((error)=>{console.log(error)});
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }
    
    return(
        <div className="login">
            <form>
                <label>用戶名稱:
                    <input value={username} onChange={handleUsernameChange} />
                </label>
                <label>密碼:
                    <input type="password" onChange={handlePasswordChange} />
                </label>
                <button onClick={submitLogin}>登入</button>
            </form>
        </div>
    );
}

export default Login;