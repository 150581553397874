import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';

function AboutUsKnowledgeCard(props) {
    const [imgDirectory, setImgDirectory] = useState(props.imgDirectory);
    const [title, setTitle] = useState(props.title);
    
  return (
    <Card>
        <Card.Img variant="top" src={imgDirectory} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text>
            <div className='aboutUsKnowledge-button'>
                更多資訊  &#9660;
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
  );
}

export default AboutUsKnowledgeCard;