import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';

export default function GoogleMapCom(){
  console.log(process.env.REACT_APP_BASE_URL); 
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
  });
  if(!isLoaded) return <div>Loading...</div>;
  return <Map />;
  
}



function Map(){
  const center= useMemo(() => ({lat:22.3693931, lng: 114.1219039}), []);
  return (
  <GoogleMap 
    zoom={18} 
    center={center}
    mapContainerClassName="map-container"
  >
    <MarkerF position={{lat:22.36923, lng: 114.12235}}/> 
  </GoogleMap>
  );
}