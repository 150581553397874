import React, { useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import '../style/Record.css';
import taxiImageAssigner from '../components/TaxiImageAssigner';

function RecordRow(props) {
    const [tradingRecord, setTradingRecord] = useState(props.tradingRecord);
    const [taxiImage, setTaxiImage] = useState("normalRedTaxi");
    useEffect(()=>{
        
        setTaxiImage(taxiImageAssigner(tradingRecord.licenceType, tradingRecord.carType));
    })
    
    
  return (
    <ListGroup.Item className="d-flex justify-content-between align-items-start">
        <img className="recordRowImage" src={require(`../images/taxiImage/${taxiImage}.jpeg`)} ></img>
        <div className="ms-2 me-auto">
            <div className='contractDay'>
                <div className='contractDay-label recordList-label'>簽約日期：</div>
                <div className='contractDay-content recordList-content'>{tradingRecord.contractDay}</div>
            </div>
            <div className='licenceType'>
                <div className='licenceType-label recordList-label'>的士牌類別：</div>
                <div className='licenceType-content recordList-content'>{tradingRecord.licenceType}</div>
            </div>
            <div className='carType'>
                <div className='carType-label recordList-label'>車輛年份型號：</div>
                <div className='carType-content recordList-content'>{tradingRecord.carType}</div>
            </div>
            <div className='deposite'>
                <div className='deposite-label recordList-label'>訂金：</div>
                <div className='deposite-content recordList-content'>{tradingRecord.deposite}萬</div>
            </div>
            <div className='trading-price'>
                <div className='price-label recordList-label'>成交價：</div>
                <div className='price-content recordList-content'>{tradingRecord.price}萬</div>
            </div>
            <div className='tradingDay'>
                <div className='tradingDay-label recordList-label'>成交期： </div>
                <div className='tradingDay-content recordList-content'>{tradingRecord.tradingDay}</div>
            </div>
        </div>
    </ListGroup.Item>
  );
}

export default RecordRow;