import React, {useState} from 'react';
import {
    MDBContainer,
    MDBCollapse,
    MDBNavbar,
    MDBNavbarToggler,
    MDBIcon,
    MDBBtn,
  } from 'mdb-react-ui-kit';

function HamburgerMenu() {
    const [showAnimated, setShowAnimated] = useState(false);
  return (
    <>
      <section className='mb-3 menu'>
        <MDBNavbar>
          <MDBContainer fluid>
            <MDBNavbarToggler
              type='button'
              className='first-button'
              data-target='#navbarToggleExternalContent'
              aria-controls='navbarToggleExternalContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
              onClick={() => setShowAnimated(!showAnimated)}
            >
              <div className={`animated-icon1 ${showAnimated && 'open'}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </MDBNavbarToggler>
          </MDBContainer>
        </MDBNavbar>

        <MDBCollapse show={showAnimated}>
          <div className='bg-light shadow-3 p-4'>
            <MDBBtn block className='border-bottom m-0' href='/'>
              主頁
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='/channel'>
              最新動態
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='/price'>
              牌價走勢
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='lease'>
              的士招租
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='record'>
              交易紀錄
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='tradingKnowledge/advantage'>
              買賣需知
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='/faq'>
              常見問題
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='aboutus'>
              關於我們
            </MDBBtn>
            <MDBBtn block className='border-bottom m-0' href='contactus'>
              聯絡我們
            </MDBBtn>
          </div>
        </MDBCollapse>
      </section>
    </>
  );
}

export default HamburgerMenu;