import React from 'react';
import '../../style/TradingKnowledge.taxiKnowledge.css'
function TradingKnowledgeTaxiKnowledge() {
    const knowledgeList = [
        {
            title:"簡介",
            content:"香港的士，即香港的出租車，「的士」一字是從計程車的英語「Taxi」音譯出來的。乘客上車後指明目的地，司機直接駕駛前往，是方便快捷的公共交通工具。車費根據車程及等候時間而定，而存放行李、電話召喚的士及使用收費道路等則需繳付附加費用。香港各區設有不少的士站供乘客等候的士，而部分停車禁區亦設有一些的士專用的上落客位置。",
        },
        {
            title:"擔當角色",
            content:"在香港，的士是主要的客運公共交通工具之一。現時，本港有18,163輛的士，包括15,250輛市區的士（紅色）、2,838輛新界的士（綠色）和75輛大嶼山的士（藍色），平均每日載客量接近一百萬人次。的士為乘客提供直達目的地的個人化運輸服務。法例規定，除個人手提行李外，的士不可以用作運載貨物，所以乘客不應租賃的士作運載貨物用途。"
        },
        {
            title:"市區的士",
            content:"市區的士，俗稱紅的、紅色跑車、紅艇、紅雞或市區的，因為車身漆上紅色而得名。市區的士可接載乘客來往除南大嶼山外全港所有有公共道路連接的地點（包括深圳灣口岸、落馬洲支線管制站（福田口岸）、港珠澳大橋香港口岸、香園圍口岸及其他邊境禁區，晚上11:00至早上6:30亦可前往落馬洲管制站（皇崗口岸））、唯於大嶼山則無論營業與否，禁止前往石門甲以南之嶼南封閉道路（如：石門甲道以南的東涌道、南大嶼山）。市區的士的收費是三種的士中最高，由於需求較大，現時有15,250輛行走。",
        },
        {
            title:"新界的士",
            content:"新界的士，俗稱綠的、草蜢、新界的或青竹蛇，因車身漆上綠色而得名，於1976年9月23日起正式發牌。雖稱為新界的士，惟並不代表能在全個新界行走，當中同屬新界的葵涌全域以及荃灣、青衣、沙田、將軍澳大部分地方均禁止新界的士駛入。"
        }
    ]
    
  return (
    <div className='tradingKnowledgeTaxiKnowledge'>
        <div className='tradingKnowledgeTaxiKnowledge-titleContainer'>
          <h1>的士基本知識</h1>
          <hr className='title-hr'></hr>
        </div>
       
        <div className='tradingKnowledgeTaxiKnowledge-contentContainer'>
            {knowledgeList.map((knowledgeRow, index)=>{
                return(
                    <div className='tradingKnowledgeTaxiKnowledge-contentContainer-Row' key={index}>
                        <div className='tradingKnowledgeTaxiKnowledge-content-title'>
                            <h4>{knowledgeRow.title}</h4>
                        </div>
                        <div className='tradingKnowledgeTaxiKnowledge-content-content'>
                            <p>
                                {knowledgeRow.content}
                            </p>
                        </div>
                        {index === (knowledgeList.length -1) ? "" : <hr className='knowledgeHr'></hr>}
                    </div>
                );
            })}
         </div>
    </div>
  );
}

export default TradingKnowledgeTaxiKnowledge;