import React, { useEffect, useState } from 'react';
import axois from 'axios';

function AddTradingRecord() {
    const [carLicenses, setCarLicense] = useState([]);
    useEffect(()=>{
        axois.get(process.env.REACT_APP_BASE_URL+'/taxiLicense/getNumber').then((response)=>{
            setCarLicense(response.data);
        }).catch((error)=>{
            console.log(error);
        });
    });

    const submitTaxiLicenseInsert = (e) => {
        e.preventDefault();

        axois.post(process.env.REACT_APP_BASE_URL+'insertNumber', {contactDay:"", tradingDay:"", deposite:"", tradingPrice: "", licenseNumber:""}).then((response)=>{
            console.log(response);

        }).catch((error)=>{
            console.log(error);
        });
    }

  return (
    <div>
        <form>
            <label>
                簽約日期:
                <input type='date' name='contractDate'/>
            </label>

            <label>
                交易日期:
                <input type='date' name='tradingDate'/>
            </label>

            <label>
                訂金:
                <input type='number' name='deposite' min='0'/>
            </label>

            <label>
                交易金額:
                <input type='number' name='tradingNumber' min='0'/>
            </label>

            <label>
                牌照號碼:
                <select id="taxi-license">
                    {carLicenses.map((carLicense, index)=>{
                        return(<option value={carLicense.license_number} key={index}>{carLicense.license_number}</option>);
                    })}
                </select>
            </label>
        </form>

    </div>
  )
}

export default AddTradingRecord