import React, { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import '../style/Lease.css'
import taxiImageAssigner from '../components/TaxiImageAssigner';
import WhatsappIcon from './WhatsappIcon';
import '../style/phoneNTab/Lease_PNT.css'

function LeaseRow(props) {
    const [leaseRow, setLeaseRow] = useState(props.leaseRow);
    const [taxiImage, setTaxiImage] = useState("normalRedTaxi");
    useEffect(()=>{
        
        setTaxiImage(taxiImageAssigner(leaseRow.area, leaseRow.car_type));
    })

  return (
    <ListGroup.Item className="d-flex justify-content-between align-items-start">
        <img className="leaseRowImage" src={require(`../images/taxiImage/${taxiImage}.jpeg`)} ></img>
        <div className="ms-2 me-auto leaseRows">
            <div className='taxiTitle'>
                <h2>{leaseRow.area}{leaseRow.car_type}</h2>
            </div>
            <div className='area'>
                <div className='area-label leaseList-label'>地區：</div>
                <div className='area-content leaseList-content'>{leaseRow.area}</div>
            </div>
            <div className='car_type'>
                <div className='car_type-label leaseList-label'>型號：</div>
                <div className='car_type-content leaseList-content'>{leaseRow.car_type}</div>
            </div>
            <div className='years'>
                <div className='years-label leaseList-label'>年份：</div>
                <div className='years-content leaseList-content'>{leaseRow.years}</div>
            </div>
            <div className='shift_type'>
                <div className='shift_type-label leaseList-label'>更期：</div>
                <div className='shift_type-content leaseList-content'>{leaseRow.shift_type}</div>
            </div>
            <div className='rentle_fee'>
                <div className='rentle_fee-label leaseList-label'>每月租金：</div>
                <div className='rentle_fee-content leaseList-content'>{leaseRow.rental_fee}萬</div>
            </div>
            <div className='lease-whatsappIcon'>
                <WhatsappIcon></WhatsappIcon>
            </div>
        </div>
    </ListGroup.Item>
  );
}

export default LeaseRow;