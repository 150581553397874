import axios from 'axios';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function AdminModifyVideo() {
    const navigate = useNavigate();
    const location = useLocation();
    const [postId, setPostId] = useState(location.state.id? location.state.id:"")
    const [tag, setTag] = useState(location.state.tag? location.state.tag: "")
    const [title, setTitle] = useState(location.state.title? location.state.title:"");
    const [url, setUrl] = useState(location.state.url? location.state.url:"");
    const [content, setContent] = useState(location.state.content? location.state.content:"");

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    }

    const handleContentChange = (e) => {
        setContent(e.target.value);
    }

    const handleTagChange = (e) => {
        setTag(e.target.value);
    }

    const submitTheChagnes = () => {
        axios.post(process.env.REACT_APP_BASE_URL+"/channel/updateVideo", {
            id:postId, 
            tag:tag,
            title: title,
            url: url,
            content: content
        }).then((response)=>{
            console.log(response);
            navigate("/The_h1ddenAdm1n_page/checkChannelVideo");
        }).catch((error)=>{
            console.log(error);
        });
    }


  return (
    <div className='adminModifyVideo'>
        <label>分類:
            <input value={tag} onChange={handleTagChange}/>
        </label>
        <label className='adminModifyChannel-title'>標題:<input className='adminModifyVideo-title' value={title} onChange={handleTitleChange}/></label>
        <label className='url-input'>影片網址:
            <input value={url} onChange={handleUrlChange}/>
        </label>
        <ReactPlayer
            className="adminModifyChannel-videoPlayer"
            url={url}
            controls
        ></ReactPlayer>
        <div>

        </div>
        <textarea className='adminModifyVideo-content' style={{width:"70vw", height:"50vh"}} onChange={handleContentChange}>{content}</textarea>
        <button onClick={submitTheChagnes}>更改</button>
    </div>
  );
}

export default AdminModifyVideo;