import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import '../style/Channel.css'
import { useNavigate } from 'react-router-dom';
import '../style/phoneNTab/Channel_PNT.css';

function Channel() {

  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const [tag, setTag] = useState("*");
  const [tagSelection, setTagSelection] = useState([]);

  useEffect(()=>{
    axios.get(process.env.REACT_APP_BASE_URL + "/channel/getTags").then((response)=>{
      setTagSelection(response.data[0]);
    }).catch((error)=>{
        console.log(error);
    }, [])

    axios.post(process.env.REACT_APP_BASE_URL + "/channel/getVideos", {tag:tag}).then((response)=>{
      setVideos(response.data[0]);
    }).catch((error)=>{
       console.log(error) 
    }, [tag]);

    
  }, [tag])

  const handleTagChange = (tagValue) => {
    setTag(tagValue);
  }

  const printVideos = () => {
    if(tagSelection != []){
      
    }
  }


  function getVideoContent(link) {
    if (link.includes('jpg') || link.includes('png')) {
      return (
      <div className='channel-content-video-left'>
        <img src={link} />
      </div>);
    } else {
      return (
      <div className='channel-content-video-left'>
          <ReactPlayer url={link}
            className="player"
            controls
          />
      </div>);
    }
  }


  return (
    <div className='channel'>
      <div className='channel-titleContainer'>
        <h1>最新 動態</h1>
        <hr className='title-hr'></hr>
      </div>
      <div className='channel-contentContainer'>
        <div className='channel-content-tagContainer'>
          <div className='channel-content-tag' onClick={()=>{handleTagChange('*')}}>全部</div>
          {tagSelection.map((theTag, key)=>{
            return(
              <div className='channel-content-tag' onClick={()=>{setTag(theTag.tag)}} key={key}>{theTag.tag}</div>
            );
          })}
        </div>
        <div className='channel-content-videoContainer'>
          {videos.map((video, key)=>{
        return(
          <div key={key} className='channel-content-video' onClick={()=>{navigate("/channelSingleNews",{state:{
            title:video.title,
            video:video.videoLink,
            content:video.content
          }})}}>
            {getVideoContent(video.videoLink)}
            <div className='channel-content-video-right'>
                <div className='channel-content-video-right-title'><h3>{video.title}</h3></div>
                <div className='channel-content-video-right-content' dangerouslySetInnerHTML={{__html:video.content.slice(0, 100)}} />
            </div>
          </div>
        );
      })  }
        </div>
      </div>
    </div>
  );
}

export default Channel;
