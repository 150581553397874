import React from 'react';
import '../style/Liabilities.css'

function Liabilities() {
  return (
    <div className="liabilities">
        <div className='liabilities-titleContainer'>
            <h1>免責聲明</h1>
            <hr className='title-hr'></hr>
        </div>
        
        <div className="liabilities-contentContainer">
            <p>
            本網站所載資料（包括任何價格，意見或預測）乃秉誠提供，並取自於所示日期相信為可靠及準確之資料來源。然而，本公司並無核實所有資料，亦不表示有關資料對閣下所需用途而言乃屬準確或完整，故不應加以依賴。本公司並無責任更新資料或改正任何其後顯現之錯誤。本網站所載述的意見、估算及其他資料可予更改或撤回，恕不預早通知。
            <br></br>
            本網站的內容並不打算向任何用戶提供買賣本網站所述的任何投資意見或任何財務、法律、會計或稅務建議，因此不應當作該等建議而予以倚賴。任何指示性報價、披露資料或分析乃按本公司秉誠作出之假設及參數而編製，並不構成本公司建議。所使用之假設及參數並非在合理條件下唯一可選取者，故此本公司並不保證任何此等報價、披露或分析之準確性、完整性或合理性。並且不表示或保證所顯示之任何表現或回報今後將能達致。過往表現並非未來回報之保證。儘管所有資料乃本公司秉誠提供，並相信資料之來源可靠，本公司概不表示或保證任何資料均屬完整、可靠、準確、具時效或適用於任何用途。
            <br></br>
            本網站提述之香港市區的士牌、新界的士牌及大嶼山的士牌之價格均可升亦可跌，在若干情況下，投資者可能損失其全部投資。過往表現並不預示未來表現。而香港市區的士牌、新界的士牌及大嶼山的士牌之發行人亦可能是唯一為香港市區的士牌、新界的士牌及大嶼山的士牌提供買賣報價的一方。投資者應評估投資此等產品涉及之風險，並諮詢其法律、財務、稅務、會計及其他專業顧問，以確保作出適合其本身情況及財務狀況的投資決定。  
            </p>
        </div>
    </div>
  );
}

export default Liabilities;