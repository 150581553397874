import React from 'react';
import FacebookIcon from './FacebookIcon';
import WhatsappIcon from './WhatsappIcon';
import '../style/Footer.css'
import '../style/phoneNTab/Footer_PNT.css'

function Footer() {
  return (
    <div className='footer'> 
        <hr></hr>
        <div className='footer-column'>
            <ul>
                <li>
                    <a href='/tradingKnowledge'>買賣需知</a>
                </li>
                <li>
                    <a href='/aboutus'>關於我們</a>
                </li>
                <li>
                    <a href='/tradingKnowledge/mortgage'>的士按揭</a>
                </li>
            </ul>
        </div>
        <div className='footer-column'>
            <ul>
                <li>
                    <a href='/market'>最新市場動態</a>
                </li>
                <li>
                    <a href='/faq'>常見問題</a>
                </li>
                <li>
                    <a href='/channel'>獨家頻道</a>
                </li>
            </ul>
        </div>
        <div className='footer-column'>
            <ul>
                <li>
                    <a href='/liabilities'>免責聲明</a>
                </li>
                <li>
                    <a href='/contactus'>聯絡我們</a>
                </li>
                <li>
                    <FacebookIcon></FacebookIcon>
                    <WhatsappIcon></WhatsappIcon>
                </li>
            </ul>
        </div>
        <div className='copyrightClaim'>
            <p>Copyright © starpower. All Right Reserved.</p>
        </div>
    </div>
  );
}

export default Footer;