import React from 'react'

function Market() {
  return (
    <div className='market'>
      <div className='market-titleContainer'>
        <h1>市場動態</h1>
        <hr className='title-hr'></hr>
      </div>
      <div className='market-contentContainer'>
      
      </div>
    </div>
  )
}

export default Market