module.exports = (area, type) => {
    let imageName = '';
    
    if(type.includes("石油氣"))
       imageName += "normal";
    else
        imageName += "hybrid";
    if(area.includes("市區")){
        imageName += "Red";
    }
    else{
        imageName += "Green";
    }
    imageName += "Taxi";
    return imageName
    
}