import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ContactUsForm() {
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState("none");
    const [showSpinnerCon, setShowSpinnerCon] = useState("none");
    const [counter, setCounter] = useState(null);
    const [showModal, setShowModal] = useState("none");
    
    useEffect(() => {
      if(counter===0){
        navigate("/");
        setCounter(null)
      }
  
      // exit early when we reach 0
      if (!counter) return;
  
      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
  
        setCounter(counter - 1);
      }, 1000);
  
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [counter]);



  const submitForm = (e) => {
    e.preventDefault();

    setShowSpinnerCon("block");
    setShowSpinner("block");
    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData);

    axios.post(process.env.BASE_URL+"/contactUs/sendForm", payload).then((response) => {
      console.log("ok");
      setShowSpinner("none");
    });
    setShowModal("block");
    setCounter(5);
  }

  return (
    <Form onSubmit={submitForm}>
        <h2>更多查詢</h2>
        <Form.Group className="mb-3">
            <Form.Label>稱呼</Form.Label>
            <Form.Control name="calledName" type="input" placeholder="請在此輸入名稱" />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>聯絡電話</Form.Label>
            <Form.Control name="tel" type="tel" placeholder="請在此輸入聯絡電話" />
        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>查詢事項/留言</Form.Label>
            <Form.Control name="message" as="textarea" rows={3} />
        </Form.Group>
        <div className='buttonContainer'>
          <Button variant="primary" type="submit" style={{
            width: "100%",
          }}>
            提交
          </Button>
        </div>
        <div className='spinnerContainer' style={{display:showSpinnerCon}}>
          <Spinner animation="border" variant="warning" size='xl' style={{
            position:"absolute",
            top: "calc(100vh - 30vw)",
            left: "calc(50vw - 10vw)",
            width: "20vw",
            height: "20vw",
            display: showSpinner
          }}/>
        </div>
        <Modal.Dialog style={{
          display:showModal,
          position:"absolute",
          top:"50%",
          left:"44%",
          border:"1px"
        }}>
          <Modal.Header></Modal.Header>
          <Modal.Body>查詢成功發出，你將在{counter}秒後會到主頁</Modal.Body>
        </Modal.Dialog>
    </Form>
  );
}

export default ContactUsForm;