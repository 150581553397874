import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AdminTaxiLicenseForm(props) {
    const [taxiLicense, setTaxiLicense] = useState(props.taxiLicense? props.taxiLicense.taxiLicense: null);
    const [carType, setCarType] = useState(props.taxiLicense? props.taxiLicense.carType : null);
    const [area, setArea]= useState(props.taxiLicense? props.taxiLicense.area:null);

    let link = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();

    const handleTaxiLicenseChange = (e) => {
        setTaxiLicense(e.target.value);
    }

    const handleCarTypeChange = (e) => {
        setCarType(e.target.value);
    }

    const handleAreaChange = (e) => {
        setArea(e.target.value);
    }

    const disabled = props.taxiLicense;

    useEffect(()=>{
        if(props.taxiLicense){
            link+="/taxiLicense/updateTaxiLicense";
        }
        else{
            link+="/taxiLicense/insertTaxiLicense";
        }})

    const clickSubmitButton = (e) => {
        e.preventDefault();
        console.log('omg');

        axios.post(link, {taxiLicense:taxiLicense, carType:carType, area:area}).then((response)=>{
            console.log(response);
            navigate("/The_h1ddenAdm1n_page/checkTaxi ")
        }).catch((error)=>{
            console.log(error);
        })
    }

  return (
    <div className='adminTaxiLicenseForm'>
        <div className='adminTaxiLicenseForm-container'>
            <form>
                <label>
                    車牌號碼:<input value={taxiLicense} onChange={handleTaxiLicenseChange} disabled={disabled}/>
                </label>
                <label>
                    種類:<input value={carType} onChange={handleCarTypeChange}/>
                </label>
                <label>
                    地區:<input value={area} onChange={handleAreaChange}/>
                </label>
                <button onClick={clickSubmitButton} type='submit'>提交</button>
            </form>
        </div>
    </div>
  );
}

export default AdminTaxiLicenseForm;