import React from 'react'

function Talk() {
  return (
    <div className='talk'>
      <div className='talk-titleContainer'>
        <h1>投資講座</h1>
        <hr className='title-hr'></hr>
      </div>
      <div className='talk-contentContainer'>
      
      </div>
    </div>
  )
}

export default Talk