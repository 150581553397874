import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

//import pages
import MainPage from './pages/Main';
import AboutUsPage from './pages/AboutUs';
import PricePage from './pages/Price';
import RecordPage from './pages/Record';
import ChannelPage from './pages/Channel';
import MarketPage from './pages/Market';
/*import TradingKnowledgePage from './pages/TradingKnowledge';*/
import TalkPage from './pages/Talk';
import FaqPage from './pages/Faq';
import LeasePage from './pages/Lease';
import ContactUsPage from './pages/ContactUs';
import AdvantagePage from './pages/TradingKnowledge.subPages/TradingKnowledge.advantage';
import TaxiKnowledgePage from './pages/TradingKnowledge.subPages/TradingKnowledge.taxiKnowledge';
import TradingProcessPage from './pages/TradingKnowledge.subPages/TradingKnowledge.tradingProcess';
import MortgagePage from './pages/TradingKnowledge.subPages/TradingKnowledge.mortgage';
import FeesAndCostsPage from './pages/TradingKnowledge.subPages/TradingKnowledge.feesAndCosts';
import ManagementAndIncome from './pages/TradingKnowledge.subPages/TradingKnowledge.managementAndIncome';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import LiabilitiesPage from './pages/Liabilities';
import PriceInsert from './pages/PriceInsert';
import AddTradingRecordPage from './pages/manageDB/AddTradingRecord';
import ChannelSingleNewsPage from './pages/ChannelSingleNews';


//this part import for admin pages
import AdminNavbar from './adminPages/adminComponents/AdminNavbar';
import AdminMainPage from './adminPages/AdminMain';
import AdminCheckTaxiPage from './adminPages/adminTaxi/AdminCheckTaxi';
import AdminCheckRecordPage from './adminPages/adminRecord/AdminCheckRecord';
import AdminCheckLeasePage from './adminPages/adminLease/AdminCheckLease';
import AdminCheckPricePage from './adminPages/adminPrice/AdminCheckPrice';
import AdminupInsertRecordPage from './adminPages/adminRecord/AdminupInsertRecord';
import AdminModifyRecordPage from './adminPages/adminRecord/AdminModifyRecord';
import AdminInsertLeasePage from './adminPages/adminLease/AdminInsertLease';
import AdminModifyLeasePage from './adminPages/adminLease/AdminModifyLease';
import AdminTaxiLicenseFormPage from './adminPages/adminComponents/AdminTaxiLicenseForm';
import AdminModifyTaxiPage from './adminPages/adminTaxi/AdminModifyTaxi';
import AdminUploadVideoPage from './adminPages/adminChannel/AdminUploadVideo';
import AdminCheckVideoPage from './adminPages/adminChannel/AdminCheckVideo';
import AdminInsertPricePage from './adminPages/adminPrice/AdminInsertPrice';
import AdminModifyVideoPage from './adminPages/adminChannel/AdminModifyVideo';


//import login Page
import LoginPage from "./loginPage/Login";
import {useCookies} from 'react-cookie';
import {SHA224} from 'crypto-js';

function App() {
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const authCookie = cookies.auth;

  const currentURL = window.location.href;
  const decidePage = (url) => {
    if(url.includes("The_h1ddenAdm1n_page") && authCookie == '147e9946ed7dae7b72bd45fdbedb74f783e36b4d74ce29bb1d9e96c8')
      return (
        <Router>
          <AdminNavbar />
            <Routes>
              <Route path="/The_h1ddenAdm1n_page/" exact Component={AdminMainPage} />
              <Route path="/The_h1ddenAdm1n_page/checkTaxi" exact Component={AdminCheckTaxiPage} />
              <Route path="/The_h1ddenAdm1n_page/checkTradingRecord" exact Component={AdminCheckRecordPage}/>
              <Route path="/The_h1ddenAdm1n_page/checkLeasingRecord" exact Component={AdminCheckLeasePage}/>
              <Route path="/The_h1ddenAdm1n_page/checkLicensePrice" exact Component={AdminCheckPricePage}/>
              <Route path="/The_h1ddenAdm1n_page/insertRecord" exact Component={AdminupInsertRecordPage}/>
              <Route path="/The_h1ddenAdm1n_page/ModifyRecord" exact Component={AdminModifyRecordPage}/>
              <Route path="/The_h1ddenAdm1n_page/insertLeaseRecord" exact Component={AdminInsertLeasePage}/>
              <Route path="/The_h1ddenAdm1n_page/ModifyLeaseRecord" exact Component={AdminModifyLeasePage}/>
              <Route path="/The_h1ddenAdm1n_page/insertTaxi" exact Component={AdminTaxiLicenseFormPage}/>
              <Route path="/The_h1ddenAdm1n_page/modifyTaxi" exact Component={AdminModifyTaxiPage}/>
              <Route path="/The_h1ddenAdm1n_page/uploadChannelVideo" exact Component={AdminUploadVideoPage}/>
              <Route path="/The_h1ddenAdm1n_page/checkChannelVideo" exact Component={AdminCheckVideoPage}/>
              <Route path="/The_h1ddenAdm1n_page/insertPrice" exact Component={AdminInsertPricePage}/>
              <Route path="/The_h1ddenAdm1n_page/modifyChannel" exact Component={AdminModifyVideoPage}/>
            </Routes>
        </Router>
      );
    else
      return(
      <Router>
        <NavBar></NavBar>
        <Routes> 
          <Route path="/" exact Component={MainPage} />
          <Route path="/aboutus" exact Component={AboutUsPage} />
          <Route path="/price" exact Component={PricePage} />
          <Route path="/record" exact Component={RecordPage} />
          <Route path="/channel" exact Component={ChannelPage} />
          <Route path="/market" exact Component={MarketPage} />
          {/*<Route path="/tradingKnowledge" exact Component={TradingKnowledgePage} />*/}
          <Route path="/talk" exact Component={TalkPage} /> 
          <Route path="/faq" exact Component={FaqPage} /> 
          <Route path="/lease" exact Component={LeasePage} />
          <Route path="/contactus" exact Component={ContactUsPage} />
          <Route path="/tradingKnowledge/advantage" exact Component={AdvantagePage}/>
          <Route path="/tradingKnowledge/taxiKnowledge" exact Component={TaxiKnowledgePage}/>
          {/*<Route path="/tradingKnowledge/tradingProcess" exact Component={TradingProcessPage}/>*/}
          <Route path="/tradingKnowledge/mortgage" exact Component={MortgagePage}/>
          <Route path="/tradingKnowledge/feesAndCost" exact Component={FeesAndCostsPage}/>
          <Route path="/tradingKnowledge/managementAndIncome" exact Component={ManagementAndIncome}/>
          <Route path="/liabilities" exact Component={LiabilitiesPage}/>
          <Route path='/insertPriceRecord' exact Component={PriceInsert} />
          <Route path='/insertTaxiLicense' exact Component={AddTradingRecordPage}></Route>
          <Route path='/channelSingleNews' exact Component={ChannelSingleNewsPage}></Route>
          <Route path="/login" exact Component={LoginPage}/>
        </Routes>
        <Footer ></Footer>
      </Router>
      )
  }
  return (
    <div className="App">
      {decidePage(currentURL)}      
    </div>
  );
}

export default App;
