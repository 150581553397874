import axios from 'axios';
import React, {useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function NavBarPrice(props) {
    const [latestPrice, setLatestPrices] = useState(props.latestPrice);
    const [today, setToday] = useState(new Date(props.today));
    const [yesterday, setYesterday] = useState(new Date(new Date(props.today).setDate(new Date(today).getDate()-1)) .toLocaleDateString("zh-CN"));
    const [yesterdayPrice, setYesterdayPrice] = useState(0);

    let tagColor = 'red';
    if(latestPrice.area.includes('nt')){
        tagColor = 'green';
    }
    let theClassname = "navBarPrice " + tagColor + "Tag priceOnNavBar-tag ";
    
    const navigate = useNavigate();
    function toPricePage(){
        navigate("/price");
    };

    useEffect(()=>{
        setToday(props.today)
        setYesterday(new Date(new Date(props.today).setDate((new Date(props.today)).getDate()-1)) .toLocaleDateString("zh-CN"));
        console.log(props.today)
        if(props.today){ 
            //let tempDate = new Date(today);
            //tempDate = new Date(tempDate.setDate(tempDate.getDate()-1));
            //setYesterday(tempDate.toLocaleDateString("zh-CN"));
            axios.post(process.env.REACT_APP_BASE_URL+"/price/getPrice",{
                countBy: "countByDay",
                dateFrom: yesterday,
                dateTo: yesterday
            }).then((response)=>{
                setYesterdayPrice(response.data[0]);
            }).catch((error)=>{
                console.log(error);
            });
        }
    },[])

    const showHigherOrLower = (area, price) => {
        if(yesterdayPrice == undefined)
            return ("");
        if(price == yesterdayPrice[area]){
            return (<p>-</p>)
        }
        else {
            return(
            <p style={{color: price > yesterdayPrice[area]?"red":"green"}}>
                {price > yesterdayPrice[area]?'▲':'▼'}{Math.abs(price-yesterdayPrice[area])}萬
            </p>
            );
        }
    }


  return (
    <div className='priceOnNavBar'>
        <div className={theClassname}
            onClick={toPricePage}
            style={{
                display: 'flex',
                flexDirection: 'row',
                borderColor: tagColor,
                backgroundColor: '#F5F5DC',
                
            }}>
                <div className='priceOnNavBar-area' style={{
                    backgroundColor: tagColor,
                    color:"#F5F5DC"
                }}>{latestPrice.area==='urban'?'市區':'新界'}</div>
                <div className='priceOnNavBar-price'>HK${latestPrice.License_Price}萬 &nbsp; 
                   {showHigherOrLower(latestPrice.area, latestPrice.License_Price)}
                </div>
            </div>

    </div>
  );
}

export default NavBarPrice;