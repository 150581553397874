import React, { useState, useEffect } from 'react'
import background from '../images/mainBackground.jpeg'
import axios from 'axios';
import '../style/Main.css';
import '../style/phoneNTab/Main_PNT.css';
function Main() {
  const [whatsappNo, setWhatsappNo] = useState('');

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/contactUs/getInfo`).then((response)=>{
      setWhatsappNo(response.data.company_whatsapp);
    }).catch((error)=>
    {
      console.log(error)
    });
  },[]);

  return (
    <div className='main'>
      <div className="main-contentContainer" style={{
        backgroundImage: `url(${background})`
      }}>
        <div className="main-content-transbox">
          <h1>STAR POWER 鴻昇車行投資有限公司</h1>
          <p className='main-contentContainer-text'>【你入行、我搞掂一條龍全包服務！】
            <br></br>
            <p>鴻昇車行投資有限公司 “的士投資 買賣 出租 托管 車身廣告 ” 專家, 大量招聘各區的士司機, 特設新人培訓獎金, 車隊柯打支持</p>
          </p>
          <a href={whatsappNo} target='blank' >
            <div className='main-content-transbox-button'>立即聯絡我們</div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Main;