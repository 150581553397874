import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../adminStyle/adminLease.css'
import { useNavigate } from 'react-router-dom';

function AdminCheckLease() {
    const navigate = useNavigate();

    useEffect(()=>{
        axios.get(process.env.REACT_APP_BASE_URL+'/getLease').then((response)=>{
            setTaxiLease(response.data.leaseList[0]);
        }).catch((error) => {
            console.log(error);
        })
    });

    const deleteRow = (recordNumber) => {
        axios.post(process.env.REACT_APP_BASE_URL+'/getLease/deleteLease', {recordNumber:recordNumber}).then((response)=>{
            console.log(response);
            navigate("/The_h1ddenAdm1n_page/checkLeasingRecord");
        }).catch((error)=>{
            console.log(error);
        });
    }
    const [taxiLease, setTaxiLease] = useState([]);
  return (
    <div className='adminCheckLease'>
        <div className='adminCheckLease-container-add' onClick={()=>navigate("/The_h1ddenAdm1n_page/insertLeaseRecord")}>+</div>
        <div className='adminCheckLease-container'>
            {taxiLease.map((lease, key)=>{
                return(
                    <div key={key} className='adminCheckLease-singleRecord'>
                        <div>租金:{lease.rental_fee}萬</div>
                        <div>牌照號碼:{lease.license_number}</div>
                        <div>種類:{lease.shift_type}</div>
                        <div>年份:{lease.years}</div>
                        <div
                            className='adminCheckLease-ModifyButton'
                            onClick={()=>{
                                navigate("/The_h1ddenAdm1n_page/modifyLeaseRecord",
                                    {state:{
                                        recordNumber:lease.record_number,
                                        rentalFee: lease.rental_fee,
                                        shiftType: lease.shift_type,
                                        licenseNumber: lease.license_number,
                                        years: lease.years
                                    }}
                                )
                            }}
                        >修改</div>
                        <br></br>
                        <button onClick={()=>{deleteRow(lease.record_number)}}>刪除</button>
                    </div>
                );
            })}
        </div>
    </div>
  );
}

export default AdminCheckLease;