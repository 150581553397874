import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function AdminRecordForm(props) {

    let cDay = props.tradingRecord? props.tradingRecord.contractDay.split("/"): " / / ";
    let tDay = props.tradingRecord? props.tradingRecord.tradingDay.split("/"): " / / ";
    const [recordId, setRecordId] = useState(props.tradingRecord? props.tradingRecord.recordId: null);
    const [contractDay, setContractDay] = useState(cDay[2]+'-'+cDay[1]+'-'+cDay[0]);
    const [tradingDay, setTradingDay] = useState(tDay[2]+'-'+tDay[1]+'-'+tDay[0]);
    const [deposite, setDeposite] = useState(props.tradingRecord? props.tradingRecord.deposite: null);
    const [tradingPrice, setTradingPrice] = useState(props.tradingRecord? props.tradingRecord.tradingPrice: null);
    const [licenseNumber, setLicenseNumber] = useState(props.tradingRecord?props.tradingRecord.licenseNumber:null);
    const [carLicenses, setCarLicense] = useState([]);

    let link = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
 
    useEffect(()=>{
        if(props.tradingRecord){
            
             
            //setContractDay(new Date(props.tradingRecord.contractDay).getFullYear()+"/"+ (new Date(props.tradingRecord.contractDay).getMonth()+1)+"/"+ new Date(props.tradingRecord.contractDay).getDate());
            //setTradingDay(new Date(props.tradingRecord.tradingDay).getFullYear()+"/"+ (new Date(props.tradingRecord.tradingDay).getMonth()+1)+"/"+ new Date(props.tradingRecord.tradingDay).getDate());
            
            link+='/getRecord/adminAlterTRecord';  
        }
        else{
            link+='/getRecord/insertTradingRecord';
        }

        axios.get(process.env.REACT_APP_BASE_URL+'/taxiLicense/getNumber').then((response)=>{
            setCarLicense(response.data);
        }).catch((error)=>{
            console.log(error);
        });
    });

    const submitTaxiLicenseInsert = (e) => {
        e.preventDefault();
        if(props.tradingRecord)
            axios.post(link, {contractDay: contractDay, tradingDay: tradingDay, deposite: deposite, tradingPrice: tradingPrice, licenseNumber:licenseNumber, recordId:recordId}).then((response)=>{
                console.log(response);
                navigate("/The_h1ddenAdm1n_page/checkTradingRecord");
            }).catch((error)=>{
                console.log(error);
            });
        else{
            console.log(contractDay, tradingDay, deposite, tradingPrice, licenseNumber);    
            axios.post(link, {contractDay: contractDay, tradingDay: tradingDay, deposite: deposite, tradingPrice: tradingPrice, licenseNumber:licenseNumber}).then((response)=>{
               
                navigate("/The_h1ddenAdm1n_page/checkTradingRecord");
            }).catch((error)=>{
                console.log(error);
            });
        }
            
        
       
    }

    const handleContractDayChange = (e) => {
        setContractDay(e.target.value);
    }

    const handleTradingDayChange = (e) => {
        setTradingDay(e.target.value);
    }

    const handleDepositeChange = (e) => {
        setDeposite(e.target.value);
    }

    const handleTradingPriceChange = (e) => {
        setTradingPrice(e.target.value);
    }

    const handleLicenseNumberChange = (e) => {
        setLicenseNumber(e.target.value);
    }

  return (
    <div className='adminInsertRecord'>
        <div className='adminInasertRecordContainer'>
            <form>
                <label>
                    簽約日期:
                    <input type='date' name='contractDate' value={contractDay} onChange={handleContractDayChange}/>
                </label>

                <label>
                    交易日期:
                    <input type='date' name='tradingDate' value={tradingDay} onChange={handleTradingDayChange}/>
                </label>

                <label>
                    訂金:
                    <input type='number' name='deposite' min='0' value={deposite} onChange={handleDepositeChange}/>
                </label>

                <label>
                    交易金額:
                    <input type='number' name='tradingNumber' min='0' value={tradingPrice} onChange={handleTradingPriceChange}/>
                </label>

                <label>
                    牌照號碼:
                    <select id="taxi-license"  onChange={handleLicenseNumberChange}>
                        {props.tradingRecord ? <option value = {props.tradingRecord.licenseNumber} selected disabled hidden>{props.tradingRecord.licenseNumber} </option> : <option value="" selected disabled hidden>-</option>}
                        {carLicenses.map((carLicense, index)=>{
                            return(<option value={carLicense.license_number} key={index}>{carLicense.license_number}</option>);
                        })}
                    </select>
                </label>
                <button onClick={submitTaxiLicenseInsert}>提交</button>
            </form>
        </div>
    </div>
  );
}

export default AdminRecordForm;