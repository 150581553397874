import React from 'react';
import RecordList from '../components/RecordList';
import '../style/phoneNTab/Record_PNT.css'

function record() {
  return (
    <div className='record'>
      <div className='record-titleContainer'>
        <h1>最新的士成交</h1>
        <hr className='title-hr'></hr>
      </div>
      
      <RecordList></RecordList>
    </div>
  );
}

export default record;