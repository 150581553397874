import React from 'react';
import '../style/QuestionAnswer.css'

function QuestionAnswer(props) {
    const questionList = props.questionList;
  return (
    <div className='questionAnswer'>
        {questionList.map((questionRow, index) => {
          return(
            <div key={index} className='questionAnswerBox'>
              <div className='questionAnswerBoxTitle'>
                <h1>{props.title}{index+1}</h1>
              </div>
              <div className='questionAnswerBoxQuestion'>
                <h3>{questionRow.question}</h3>
              </div>
              <div className='questionAnswerBoxAnswer'>
                <p dangerouslySetInnerHTML={{__html: questionRow.answer}} />
              </div>
            </div>
          );
        })}
      </div>
  );
}

export default QuestionAnswer;