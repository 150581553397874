import React from 'react';
import '../../style/Tradingknowledge.mortgage.css'

function TradingKnowledgeMortgage() {
  return (
    <div className='tradingKnowledgeMortgage'>
        <div className='tradingKnowledgeMortgage-titleContainer'>
            <h1>的士按揭</h1>
            <hr className='title-hr'></hr>
        </div>
        <div className='tradingKnowledgeMortgage-contentContainer'>
            <p>鴻昇的士可以幫你申請的士按揭及貨款
              <br></br>
              根據現時金管局的指引，銀行或財務機構對購入的士的按揭貸款額最高為市價 (牌價+車輛) 的 85%。現時，在香港有多間銀行或財務機構也會提供的士按揭貸款，他們有不同的按揭計劃，提供不同的按揭利率、貸款金額及還款年期。客人可以根據以上的因素來選擇最合適的按揭計劃。
              </p>
              <br></br>
              <p>現時市場上一般的按揭貸款計劃細節:</p>
              <ul>
                <li>貸款金額約為市價 (牌價+車輛) 的 70% 至 80%</li>
                <li>按揭利率為 P-1%</li>
                <li>還款年期最長可達30年</li>
              </ul>
              <br></br>
              <p>申請按揭貸款時需要的文件:</p>
              <ul>
                <li>申請人香港身份證* (如以公司名義申請則須呈遞公司註冊證書及商業登記證)</li>
                <li>住址證明*</li>
                <li>車輛登記証</li>
                <li>的士駕駛許可證</li>
                <li>銀行月結單*</li>
                <li>商業登記証* (若沒有，可代你辦理)</li>
                <li>入息記錄*</li>
                <h11>*為必要事項</h11>
              </ul>
              <p>如需要了解更多資料或欲申請的士按揭，歡迎與我們聯絡。</p>
        </div>
    </div>
  );
}

export default TradingKnowledgeMortgage;