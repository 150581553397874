import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../adminStyle/adminPrice.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

function AdminCheckPrice() {
    const [taxiPrices, setTaxiPrices] = useState([]);
    const [dateTo, setDateTo] = useState(new Date());
    const [dateFrom, setDateFrom] = useState('2000-01-01');
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [changeUrban, setChangeUrban] = useState();
    const [changeNt, setChangeNt] = useState();
    const [modalDate, setModalDate] = useState();
    const [modalId, setModalId] = useState();

    useEffect(()=>{
        axios.post(process.env.REACT_APP_BASE_URL+'/price/getPrice',{
            countBy:"countByDay",
            dateFrom:dateFrom,
            dateTo:dateTo
        }).then((response)=>{
            setTaxiPrices(response.data.reverse());
        }).catch((error)=>{
            console.log(error)
        });
    });

    const handleDateFromChange = (e) => {
        setDateFrom(e.target.value);
    }

    const handleDateToChange = (e) => {
        setDateTo(e.target.value);
    }

    const handleClose = () => setShow(false);
    const activateChange = (title, up, np, id) =>{
        setShow(true);
        setModalDate(title);
        setChangeUrban(up);
        setChangeNt(np);
        setModalId(id)
    }

    const handlePriceChange = () => {
        axios.post(process.env.REACT_APP_BASE_URL+"/price/updateTaxiLicensePrices", {
            priceDate:modalId,
            urban: changeUrban,
            nt:changeNt
        }).then((response)=>{

            setShow(false)
            console.log(response);
        }
        ).catch();
    }

    const handleUrbanChange = (e) =>{
        setChangeUrban(e.target.value);
    }
    
    const handleNtChange = (e) => {
        setChangeNt(e.target.value);
    }
  return (
    <div className='adminCheckPrice'>
        <label>日期由:
            <input type='date' value={dateFrom} onChange={handleDateFromChange}></input>   
        </label>
        <label>日期至:
            <input type='date' value={dateTo} onChange={handleDateToChange}></input>   
        </label>
        <div className='adminCheckPrice-container-add' onClick={()=>navigate("/The_h1ddenAdm1n_page/insertPrice")}>新增牌價</div>
        <div className='adminCheckPrice-container'>
            <table>
                <tr>
                    <th>日期</th>
                    <th>市區牌價</th>
                    <th>新界牌價</th>
                </tr>
                {taxiPrices.map((price, key)=>{
                    return(
                        <tr key={key}>
                            <td>{price.name}</td>
                            <td>{price.urban}</td>
                            <td>{price.nt}</td>
                            <td><button onClick={() => activateChange(price.name, price.urban, price.nt ,price.name)}>修改</button></td>
                        </tr> );
                })}
            </table>
        </div>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalDate}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>市區牌價:
                    <input  value={changeUrban} onChange={handleUrbanChange}/>
                </label>
                <label>新界牌價:
                    <input value={changeNt} onChange={handleNtChange}/>
                </label>
                <button onClick={handlePriceChange}>確定</button>
                <button onClick={handleClose}>取消</button>
            </Modal.Body>
        </Modal>
    </div>
  );
}

export default AdminCheckPrice;