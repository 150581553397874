import React, { useState } from 'react';
import axios from 'axios';
import '../adminStyle/adminRecord.css'
import { useNavigate } from 'react-router-dom';

function AdminCheckRecord() {
  const navigate = useNavigate();
  const [taxiTradingRecord, setTaxiTradingRecord] = useState([]);
  
  useState(()=>{
    axios.get(process.env.REACT_APP_BASE_URL+"/getRecord").then((response)=>{
      setTaxiTradingRecord(response.data.record[0]);
    }).catch((error)=>{
      console.log(error);
    });
  });

  const deleteTrading = (recordNumber) => {
    axios.post(process.env.REACT_APP_BASE_URL+"/getRecord/deleteTrading", {recordNumber:recordNumber}).then((response)=>{
      console.log(response);
      navigate(0);
    }).catch((error)=>{
      console.log(error);
    });
  }

  return (
    <div className='adminCheckRecord'>
        <div className='adminCheckRecord-container-add' onClick={()=>navigate("/The_h1ddenAdm1n_page/insertRecord")}>+</div>
        <div className='adminCheckRecord-container'>
          {taxiTradingRecord.map((record, key)=>{
            return (<div className='single-adminCheckRecord' key={key}>
                <div>簽約日期:{new Date(record.contract_date).toLocaleDateString('zh-CN')}</div>
                <div>交易日期:{new Date(record.trading_date).toLocaleDateString('zh-CN')}</div>
                <div>訂金:{record.deposite}萬</div>
                <div>交易金額:{record.trading_price}萬</div>
                <div>牌照號碼:{record.license_number}</div>

                <div onClick={()=>{navigate("/The_h1ddenAdm1n_page/ModifyRecord", {state:{
                  recordId:record.record_number,
                  contractDay: new Date(record.contract_date).toLocaleDateString(),
                  tradingDay: new Date(record.trading_date).toLocaleDateString(),
                  deposite: record.deposite,
                  tradingPrice: record.trading_price,
                  licenseNumber : record.license_number
                }})}}
                className='adminCheckRecod-ModifyButton'
                >修改</div>
                <br></br>
                <button onClick={()=>deleteTrading(record.record_number)}>刪除</button>
            </div>);
          })}
        </div>
    </div>
  );
}

export default AdminCheckRecord;