import React, { useEffect, useState } from 'react';
import whatsappIcon from '../images/whatsappIcon.png';
import axios from 'axios';
function WhatsappIcon() {
  var wtsLoaded = false;
  const [whatsappUrl, setWhatsappUrl] = useState({});

  useEffect(()=>{
    axios.get(process.env.REACT_APP_BASE_URL+"/contactUs/getInfo").then((response) => {
      console.log(response);
      setWhatsappUrl(response.data.company_whatsapp);
    }).catch((error)=>{
      console.log(error);
      console.log(process.env.REACT_APP_BASE_URL);
    });
    wtsLoaded=true;
  },[wtsLoaded]);
  return (
    <a href={whatsappUrl} target='blank' className='nav-icon'>
        <img src={whatsappIcon} style={{
            width: "50px",
            height: "50px"
        }}
        alt='6802 6808'
        ></img>
    </a>
  );
}

export default WhatsappIcon;
